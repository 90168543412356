const APPLICATION = require('../config/application.json')

/**
 * 환경변수 별 Base Url 추출
 * @param PROJECT (WEB, PROJECT, ARTIST, BOARD, USER, SHOPPING, PAY, ORDER, ADMIN, ADMIN, EMAIL)
 */
export function getBaseUrl(PROJECT) {
  switch (process.env.NODE_ENV) {
    case 'dev':
      return APPLICATION.DEV_BASE_URL[PROJECT]
    case 'prestage':
      return APPLICATION.DEV_BASE_URL[PROJECT]
    case 'stag':
      return APPLICATION.STAG_BASE_URL[PROJECT]
    case 'prod':
    case 'prod-old':
      return APPLICATION.PROD_BASE_URL[PROJECT]
    case 'stress':
      return APPLICATION.STRESS_BASE_URL[PROJECT]
    default:
      return ''
  }
}

/**
 * 로그 노출값
 * @returns DETAIL: 전부, SIMPLE: 간략히, NONE: 전부 생략
 */
export function getLogStat() {
  switch (process.env.NODE_ENV) {
    case 'dev':
      return APPLICATION.API_LOG_DEV
    case 'prestage':
      return APPLICATION.API_LOG_STAG
    case 'stag':
      return APPLICATION.API_LOG_STAG
    case 'prod':
    case 'prod-old':
      return APPLICATION.API_LOG_PROD
    case 'stress':
      return APPLICATION.API_LOG_PROD
    default:
      return ''
  }
}

/**
 * @param req
 * @returns {{accessToken, refreshToken}|null}
 */
export function getToken(req) {
  if (req && req.headers && req.headers['x-makestar-h-t']) {
    return {
      accessToken: req.headers['x-makestar-h-t'],
      refreshToken: req.headers['x-makestar-h-r-t']
    }
  } else {
    return null
  }
}
