import Vue from 'vue'
import makeStarApi from '~/api/makeStar'
import codeConst from '~/utils/codeConst'
import toastContent from '~/components/toast/toastContent'

const { LOGIN_RESULT_STATUS } = codeConst

/** CDN 주소 설정 */
function replaceUrls(response) {
  const countryCode = response?.config?.headers?.['X-GeoIP-Country']
  if (process.env.NODE_ENV !== 'dev' && countryCode) {
    const changeUrls = {
      'prod-old': [
        'd238m8ukn6hkhb.cloudfront.net'// 이미지
      ],
      prod: [
        'd238m8ukn6hkhb.cloudfront.net'// 이미지
      ],
      stag: [
        'cfw-makestar-staging.s3.ap-northeast-2.amazonaws.com'
      ],
      prestage: [
        'cfw-makestar-staging.s3.ap-northeast-2.amazonaws.com'
      ],
      dev: [
        'cfw-makestar-staging.s3.ap-northeast-2.amazonaws.com'
      ]
    }

    const targetUrl = {
      'prod-old': 'static1-img.makestar.co',
      prod: 'static1-img.makestar.co',
      stag: 'makeuni2026.com',
      prestage: 'dev-old.makeuni2026.com',
      dev: 'dev-old.makeuni2026.com'
    }
    let json = JSON.stringify(response.data)

    changeUrls[process.env.NODE_ENV].forEach((url) => {
      const reg = new RegExp(url, 'g')
      json = json.replace(reg, targetUrl[process.env.NODE_ENV])
    })
    response.data = JSON.parse(json)
  }
  return response
}

/**
 * 토큰 정보 가져오기
 * @param context
 * @returns {*|{accessToken: string, refreshToken: string}}
 */
function getTokenInfo(context) {
  let tokenInfo = context.store.getters.tokenInfo
  if (!tokenInfo.accessToken) {
    const cookieData = context.app.$cookies.get('tokenInfo')
    if (cookieData && cookieData.accessToken) {
      tokenInfo = cookieData
    }
  }
  return tokenInfo
}

/**
 * MakeStar Api
 * @param context
 * @param inject
 */
export default (context, inject) => {
  const makeStarAxios = context.$axios.create()

  context.$toast = Vue.prototype.$toast
  context.$t = Vue.prototype.$t
  // context.$router = Vue.prototype.$router

  function redirectToLogin(context) {
    context.redirect(`/login?redirectUrl=${context.route.path}`)
  }

  makeStarAxios.setBaseURL('/_n')
  /**
   * Request Intercepter
   */
  makeStarAxios.onRequest((config) => {
    // IP 트래킹
    if (process.server && context.req) {
      if (context.req.headers['x-original-forwarded-for']) {
        config.headers['x-original-ssr'] = context.req.headers['x-original-forwarded-for']
        config.headers['x-original-forwarded-for'] = context.req.headers['x-original-forwarded-for']
      } else if (context.req.headers['x-forwarded-for']) {
        config.headers['x-original-ssr'] = context.req.headers['x-forwarded-for']
        config.headers['x-original-forwarded-for'] = context.req.headers['x-forwarded-for']
      } else if (context.req.socket && context.req.socket.remoteAddress) {
        config.headers['x-original-ssr'] = context.req.socket.remoteAddress
        config.headers['x-original-forwarded-for'] = context.req.socket.remoteAddress
      }
    }

    const { accessToken, refreshToken } = getTokenInfo(context)
    config.headers['x-makestar-h-t'] = accessToken
    config.headers['x-makestar-h-r-t'] = refreshToken

    // 현재 언어 세팅
    if (context.app && context.app.i18n && context.app.i18n.locale) {
      config.headers['x-client-lang'] = context.app.i18n.locale
      config.params = {
        ...config.params,
        lang: context.app.i18n.locale
      }
    }

    if (process.server) {
      config.baseURL = 'http://localhost:9529' + '/_n' //  서버사이드 호출일경우 자기자신호출
    }

    return config
  })
  makeStarAxios.onRequestError((e) => {
    // console.log('요청 에러: ', error)
  })

  /**
   * Response Intercepter
   */
  makeStarAxios.onResponse(async(res) => {
    const clearUserData = () => {
      context.app.store.dispatch('modules/loginuserinfo/setTokenInfo', {})
      context.app.store.dispatch('modules/loginuserinfo/setLoginInfo', {})
      broadcastTokensToApp(null)
    }

    const refreshTokens = async() => {
      try {
        context.app.store.dispatch('modules/request/setIsRefreshingToken', true)
        const authResult = await context.app.$makeStarApi.user.refreshToken()
        if (authResult.resCd === '1000') {
          const {
            accessToken,
            refreshToken
          } = authResult.resData

          context.app.store.dispatch('modules/loginuserinfo/setTokenInfo', {
            accessToken,
            refreshToken
          })

          const email = context.app.store.getters.loginInfo.email

          broadcastTokensToApp(accessToken, refreshToken, email)

          context.app.store.dispatch('modules/request/setIsRefreshingToken', false)
          return {
            result: true
          }
        }
        context.app.store.dispatch('modules/request/setIsRefreshingToken', false)
        return {
          result: false
        }
      } catch (e) {
        console.error(e)
        context.app.store.dispatch('modules/request/setIsRefreshingToken', false)
        return {
          result: false
        }
      }
    }

    const broadcastTokensToApp = (accessToken = '', refreshToken = '', email = '') => {
      // 웹뷰 환경이 아니라면 실행하지 않습니다.
      if (!window.flutter_inappwebview) return
      const payload = accessToken
        ? {
          accessToken,
          refreshToken,
          email
        }
        : null
      const params = JSON.stringify(payload)
      window.flutter_inappwebview.callHandler('sessionBroadcastHandler', params)
    }

    const tokenLogin = async() => {
      try {
        const authResult = await context.app.$makeStarApi.user.tokenLogin()
        if (authResult.resCd === '1000') {
          const { accessToken, refreshToken } = authResult.resData
          await context.app.dispatch('modules/loginuserinfo/setTokenInfo', {
            accessToken,
            refreshToken
          })

          await dispatchUserProfileInfo()
          const email = context.app.store.getters.loginInfo.email
          broadcastTokensToApp(accessToken, refreshToken, email)

          return {
            status: LOGIN_RESULT_STATUS.SUCCESS
          }
        }
        // else {
        //   clearUserData()
        //   return {
        //     result: false
        //   }
        // }
      } catch (e) {
        return {
          status: LOGIN_RESULT_STATUS.FAIL
        }
      }
    }

    const dispatchUserProfileInfo = async() => {
      const result = await context.app.$makeStarApi.user.loginUserProfile()
      if (!result) return
      if (result.resCd === '1000' && result.resData.loggedInUser) {
        await context.app.$store.dispatch('modules/loginuserinfo/setLoginInfo', result.resData)
      }
    }

    if (res.data.resCd && res.data.resCd !== '1000') {
      const { resCd } = res.data

      // 절대 경로인지 상대 경로인지 확인
      const isAbsoluteURL = /^https?:\/\//i.test(res.config.url)

      // ClientSide Rendering Only
      // Noti, 카트 권한 에러 예외 추가
      const pathName = isAbsoluteURL ? new URL(res.config.url).pathname : res.config.url
      if (resCd === '6229' && !['/user/v1/noti', '/shopping/v1/cart/cnt'].includes(pathName)) {
        clearUserData()
        redirectToLogin(context)
      }

      const isTermsSignupRequired = resCd === '6237'
      if (isTermsSignupRequired) {
        // 약관 동의 회원가입 화면으로 리다이렉트
        context.redirect(`/signup/terms?redirectUrl=${context.route.fullPath}`)
      }

      const isTokenInvalid = resCd === '6233'
      if (isTokenInvalid) {
        clearUserData()
        redirectToLogin(context)
      }

      const isTokenNeedReauthenticate = resCd === '6234'
      if (isTokenNeedReauthenticate) {
        if (context.app.store.getters.isRefreshingToken) {
          // 이미 토큰 갱신 요청이 진행중이라면 중복 요청을 보낼 수 없도록 처리
          const interval = setInterval(() => {
            if (!context.app.store.getters.isRefreshingToken) {
              clearInterval(interval)

              const { config } = res
              return makeStarAxios(config)
            }
          }, 1000)
        } else {
          const authenticateResult = await refreshTokens()
          if (authenticateResult.result === true) {
            const { config } = res
            return makeStarAxios(config)
          }
        }
      }

      const isLoginNeededWithToken = (resCd === '6238' || resCd === '6235')
      if (isLoginNeededWithToken) {
        const loginResult = await tokenLogin()
        if (loginResult.status === LOGIN_RESULT_STATUS.SUCCESS) {
          const { config } = res
          return makeStarAxios(config)
        }
      }

      const ALLOWED_RES_CODES = ['1000', '6233', '6234', '6235', '6237', '6238']

      // 댓글 api 얼럿을 노출 시키지 않기 위해 조건 추가
      if (!/board\/v1\/poll\/(\w+)\/comment/g.test(res.config.url) && !/board\/v1\/project\/(\w+)\/update\/(\w+)\/comment/g.test(res.config.url) &&
        !ALLOWED_RES_CODES.includes(res.data)
      ) {
        if (context.$toast && context.$t && (!['/user/v1/noti', '/shopping/v1/cart/cnt'].includes(pathName))) {
          if (Vue.prototype.hiddenToast) {
            return res
          }
          Vue.prototype.toastIcon = codeConst.TOAST_ICON.ERROR
          Vue.prototype.toastMessage = res.data.resMsg
          context.$toast.error(toastContent, {
            position: 'top-right',
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: 'button',
            icon: false,
            rtl: false
          })
        }
      }
    }

    res = replaceUrls(res)

    return res
  })

  // makeStarAxios.onResponseError((error) => {
  //   console.log('에러: ', error)
  // })

  // 커스텀 Axios 전역으로 등록
  inject('makeStarApi', makeStarApi(makeStarAxios))
}
